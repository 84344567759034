import * as React from "react"
import {Helmet} from "react-helmet"
import { Link } from "gatsby"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

// styles
const pageStyles = {
  backgroundColor: "#f8f9ff",
  color: "#232129",
}
const container = {
  padding: "36px",
  margin: "0  auto",
  maxWidth: "70%",
  borderRadius: "24px",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
}
const headingStyles = {
  margin: "0 auto",
  padding: "24px",
}
const customStyles = {
  backgroundColor: "#400b75",
  textAlign: "left",
  marginBottom: "100px",
  padding: "8px 64px",
}
const paragraphStyles = {
  margin: "36px 0",
  fontSize: "20px"
}

// markup
const NotFoundPage = () => {
  return (
    <div style={pageStyles}>
      <Helmet
          htmlAttributes={{ lang: "en" }}
          title="PlanetFx - Not Found"
          meta={[{ name: "description", content: "Planet Fx adalah studio kreatif pembuatan Company Profile, Iklan, Explainer, ILM, Teaser, Bumper berupa Animasi Flat Motion Graphic dan juga pembuatan Media Interaktif dan Design" }]}>
      </Helmet>
      <main>
      <Navbar style={customStyles}/>
      <article style={container}>
        <title>Not found</title>
        <h1 style={headingStyles}>Page not found</h1>
        <h3>Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
              😔
          </span>{" "}</h3>
        <p style={paragraphStyles}>
          we couldn’t find what you were looking for.
          <br /><br />
          <Link to="/"><h4>Back to Homepage</h4></Link>
        </p>
      </article>
      </main>
      <br />
      <Footer/>
    </div>
  )
}

export default NotFoundPage
